<template>
	<div>
		<!-- 头部logo -->
		<headLogo @navigation="navigation"></headLogo>
		<div class="contact" style="margin-top:96px;">
			<el-row class="map-box">
				<div class="information-imgbox">
					<div class="information-img">
						<el-row class="information">
							<el-row class="information-emailTell">
								<el-row>
									<el-col :span="9">E-mail:</el-col>
									<el-col :span="9" :offset="6">Tell:</el-col>
								</el-row>
								<el-row class="data">
									<el-col :span="9">{{list.email}}</el-col>
									<el-col :span="9" :offset="6">{{list.tell}}</el-col>
								</el-row>
							</el-row>
							
							<el-row class="information-address">
								<el-row>
									Address:
								</el-row>
								<el-row class="data">
									{{list.adress}}
								</el-row>
							</el-row>
						</el-row>
					</div>
				</div>
			</el-row>
			<!-- 画布 -->
			<div class="coverage">
				<el-image class="coverage-img" src="/home/coverage.png"></el-image>
				<el-image class="coverage-newsimg" src="/index/site.png"></el-image>
			</div>
			
			<div class="write-information">
				<el-row>
					<el-col :span="7" :offset="1">
						<input v-model="form.name" placeholder="NAME" class="write-name" maxlength="50">
					</el-col>
					<el-col :span="7" :offset="1">
						<input v-model="form.phone" placeholder="TELEPHONE" class="write-name" maxlength="20">
					</el-col>
					<el-col :span="7" :offset="1">
						<input v-model="form.email" placeholder="E-MAIL" class="write-name" maxlength="150">
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="23" :offset="1">
						<textarea rows="50" cols="3" v-model="form.question" placeholder="YOUR QUESTION" class="write-question" maxlength="1000"></textarea>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="10" :offset="7">
						<button class="submit" @click="submit">SUBMIT</button>
					</el-col>
				</el-row>
			</div>
		</div>
		
		<!-- 菜单导航 -->
		<meauDialog :meauValue="navigationvalue" @meauFn="menu"></meauDialog>
		
		<!-- 底部导航 -->
		<bottomNavigation></bottomNavigation>
	</div>
</template>

<script>
	import { selectContentListById } from '@/api/index.js'
	// 头部组件
	import headLogo from '@/components/headLogo.vue'
	// 底部导航
	import bottomNavigation from '@/components/bottomNavigation.vue'
	// 菜单导航
	import meauDialog from '@/components/meauDialog.vue'
	
	// 保存留言信息接口
	import { 
		savaLeave,
		contentList,
	} from '@/api/index.js'
	export default
	{
		data()
		{
			return{
				// 导航值
				navigationvalue:false,
				
				form:
				{
					name:'',
					phone:'',
					email:'',
					question:'',
					time:''
				},
				list:{}
			}
		},
		components:
		{
			headLogo,
			bottomNavigation,
			meauDialog
		},
		created()
		{
			this.getList()
		},
		methods:
		{
			// 头部传来的数据
			navigation(v)
			{
				this.navigationvalue=v;
				console.log(this.navigationvalue,'头部传递菜单导航')
			},
			// 遮罩层传回来的数据
			menu(v)
			{
				this.navigationvalue=v;
			},
			// 获取数据
			getList()
			{
				selectContentListById(
				{
					chnanelType: 1,
					moduleType: 'MODULE_CONTACT'
				})
				.then((response)=>
				{
					console.log(response.data.data)
					this.list=response.data.data[0];
				})
			},
			// 提交
			submit()
			{
				if(this.form.name=='' || this.form.phone=='' || this.form.email=='' || this.form.question=='' || this.form.question=='')
				{
					this.$message.error('Please fulfill the above contents accurately and guarantee the reliability of the contents.')
					return false;
				}
				else
				{
					var date=new Date();
					this.form.time=date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+' '+date.getHours()+':'+date.getMinutes()+':'+date.getSeconds();
					savaLeave({name:this.form.name,phone:this.form.phone,mail:this.form.email,leaveContent:this.form.question,createTime:this.form.time})
					.then((response)=>
					{
						this.open()
						this.form={}
					})
				}
			},
			open()
			{
				this.$alert('Message sent successfully', 'successfully');
			}
		}
	}
</script>

<style scoped>
	.contact{
		width: 100%;
		margin:0 auto;
	}
	.coverage{
		width: 100%;
		height: 0px;
		position: relative;
	}
	.coverage .coverage-img{
		z-index: 9;
		position: absolute;
		width: 100%;
		height: 50px;
		left: 0px;
		bottom: 0px;
	}
	.coverage .coverage-newsimg{
		width: 35px;
		height: 35px;
		position: absolute;
		left: 49%;
		bottom: 5px;
		z-index: 99;
	}
	.map-box{
		width: 100%;
		height: 492px;
		background-image: url('/index/map.png');
		background-size:100%;
		margin: 0 auto;
	}
	.information-imgbox{
		width: 1170px;
		margin: 0 auto;
	}
	.information-img{
		width: 747px;
		height: 320px;
		top: 65px;
		margin-left: 120px;
		position: relative;
		background-image: url('/index/informationbox.png');
		background-size:100%;
	}
	.information{
		width: 577px;
		color: white;
		font-size: 16px;
		text-align: left;
		position: absolute;
		left: 136px;
		top: 144px;
	}
	.information-emailTell{
		height: 38px;
		font-size: 16px;
		color: #FFFFFF;
	}
	.information-address{
		width: 577px;
		font-size: 16px;
		color: #FFFFFF;
		margin-top: 52px;
	}
	.write-information{
		width: 60%;
		margin-left: 20%;
		margin-top: 20px;
	}
	.write-name{
		width: 100%;
		height: 40px;
		border: solid 2px #DCDCDC;
		background-color: #EFEFEF;
		line-height: 40px;
		border-radius: 50px;
		padding-left: 5%;
	}
	.write-question{
		width: 100%;
		height:100px;
		border: solid 2px #DCDCDC;
		background-color: #EFEFEF;
		border-radius: 20px;
		margin-top: 20px;
		padding: 20px 10px;
		resize:none;
	}
	.submit{
		width: 100%;
		height: 50px;
		border: none;
		background-color: #FED000;
		border-radius: 50px;
		margin-top: 50px;
		font-size: 18px;
	}
	.data{
		margin-top: 3px;
	}
</style>